import { OptionType } from "../components/Forms/RecipeForm/RecipeIngredientForm";

const additionalSortOptions: OptionType[] = [
  {
    value: "favourites",
    label: "Favourites",
  },
];

export default additionalSortOptions;
