import "@/styles/globals.css";
import "@/styles/smartBarStyles.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { NextPage } from "next";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import "../utils/appInsights";

import NextNProgress from "nextjs-progressbar";
import { ReactElement, ReactNode } from "react";
import { Toaster } from "react-hot-toast";

import "../components/Cards/styles.css";
import "../components/Carousels/styles.css";

import TimeAgo from "javascript-time-ago";

import useCapacitorAuth from "@/hooks/useCapacitorAuth";
import en from "javascript-time-ago/locale/en.json";
import "react-datepicker/dist/react-datepicker.css";
import { redirectUriConfig } from "../auth.config";
import AppUpgradeRequired from "../components/Modals/AppUpgradeRequiredModal";
import { AuthProvider } from "../contexts/AuthContext";
import useSafeAreaInsets from "../hooks/useSafeAreaInsets";
import "../styles/react-date-picker.css";
import { isNative } from "../utils";

TimeAgo.addDefaultLocale(en);

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const mutationCache = new MutationCache({
  onError: (error) => {
    // any error handling code...
    console.error(error);
  },
});

const queryCache = new QueryCache({
  onError: (error) => {
    // any error handling code...
    console.error(error);
  },
});
const queryClient = new QueryClient({
  mutationCache,
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      //If you click in the console and then jump back it'll refetch the query. Quite annoying when devving
      staleTime: 3 * (60 * 1000), // 3 mins
    },
  },
});

const theme = extendTheme({
  formFieldHorizontalGap: "10px",
  colors: {
    brand: {
      "50": "#f9f6fd",
      "100": "#e5daf8",
      "200": "#d3bef4",
      "300": "#b795ec",
      "400": "#a379e7",
      "500": "#8952e0",
      "600": "#7434db",
      "700": "#6023c0",
      "800": "#4f1d9e",
      "900": "#3b1676",
    },
  },
});

export const brand500Color = "#8952e0";

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  useCapacitorAuth();

  const getLayout = Component.getLayout || ((page) => page);
  const insets = useSafeAreaInsets();
  const {
    query: { callbackUrl },
    push,
    isReady,
    pathname,
    basePath,
    route,
  } = useRouter();

  const onRedirectCallback = (appState: any) => {
    // Use Next.js's Router.replace method to replace the url
    Router.replace(appState?.returnTo || "/recipes");
  };

  let redirect_uri;

  if (isNative) {
    redirect_uri = redirectUriConfig;
  } else {
    redirect_uri =
      typeof window !== "undefined"
        ? window.location.origin + "/recipes"
        : undefined;
  }

  return (
    <div
      style={{
        paddingTop: insets?.insets.top,
        paddingBottom: insets?.insets.bottom,
        paddingRight: insets?.insets?.right,
        paddingLeft: insets?.insets?.left,
      }}
    >
      {/* <AppUrlListener /> */}

      <AuthProvider>
        <div>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider resetCSS theme={theme}>
              <DefaultSeo
                facebook={{
                  appId: "1291828925047612",
                }} //TODO: see if the remaining properties can get added. e.g keywords, viewport etc
                title="Recipe Log"
                description="Keep all your favorite recipes in one place and easily access them
            from anywhere, anytime. 
            
            Share recipes with your friends and say
            goodbye to messy recipe books and hello to a seamless cooking
            experience."
                additionalLinkTags={[
                  {
                    rel: "apple-touch-icon",
                    href: "/icon-192x192.png",
                  },
                  {
                    rel: "android-touch-icon",
                    href: "/icon-192x192.png",
                  },
                  {
                    rel: "manifest",
                    href: "/manifest.json",
                  },
                ]}
                additionalMetaTags={[
                  {
                    property: "keywords",
                    content:
                      "recipes, recipe log, recipe app, share recipes, recipe, cook, cookbook",
                  },
                  {
                    name: "theme-color",
                    content: "#fff",
                  },
                ]}
                openGraph={{
                  images: [
                    {
                      url: "https://www.recipelog.app/open_graph_image.png",
                    },
                  ],
                }}
              />
              <AppUpgradeRequired />
              <Toaster containerStyle={{ top: insets?.insets.top }} />
              <NextNProgress
                height={4}
                color="#8952e0"
                transformCSS={(css) => {
                  // css is the default css string. You can modify it and return it or return your own css.
                  const cssUpdated = `
                  #nprogress {
                    pointer-events: none;
                  }
                  #nprogress .bar {
                    background: #8952e0;
                    position: fixed;
                    z-index: 9999;
                    top: ${insets?.insets.top ? insets?.insets.top : 0}px;
                    left: 0;
                    width: 100%;
                    height: 4px;
                  }
                  #nprogress .peg {
                    display: block;
                    position: absolute;
                    right: 0px;
                    width: 100px;
                    height: 100%;
                    // box-shadow: 0 0 10px #8952e0, 0 0 5px #8952e0;
                    opacity: 1;
                    // -webkit-transform: rotate(3deg) translate(0px, -4px);
                    // -ms-transform: rotate(3deg) translate(0px, -4px);
                    // transform: rotate(3deg) translate(0px, -4px);
                  }
                  #nprogress .spinner {
                    display: block;
                    position: fixed;
                    z-index: 1031;
                    top: ${insets?.insets.top || 15}px;
                    right: 15px;
                  }
                  #nprogress .spinner-icon {
                    width: 32px;
                    height: 32px;
                    box-sizing: border-box;
                    border: solid 2px transparent;
                    border-top-color: #8952e0;
                    border-left-color: #8952e0;
                    border-radius: 50%;
                    -webkit-animation: nprogresss-spinner 400ms linear infinite;
                    animation: nprogress-spinner 400ms linear infinite;
                  }
                  .nprogress-custom-parent {
                    overflow: hidden;
                    position: relative;
                  }
                  .nprogress-custom-parent #nprogress .spinner,
                  .nprogress-custom-parent #nprogress .bar {
                    position: absolute;
                  }
                  @-webkit-keyframes nprogress-spinner {
                    0% {
                      -webkit-transform: rotate(0deg);
                    }
                    100% {
                      -webkit-transform: rotate(360deg);
                    }
                  }
                  @keyframes nprogress-spinner {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                  `;
                  return <style>{cssUpdated}</style>;
                }}
              />
              {getLayout(<Component {...pageProps} />)}
              {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
            </ChakraProvider>
          </QueryClientProvider>
        </div>
      </AuthProvider>
    </div>
  );
}

{
  /* <Head>
              <title key="title">Recipe Log</title>
              <meta key="ogTitle" property="og:title" content="Recipe Log" />
              <meta
                key="description"
                name="description"
                content="Keep all your favorite recipes in one place and easily access them
                from anywhere, anytime. 
                
                Share recipes with your friends and say
                goodbye to messy recipe books and hello to a seamless cooking
                experience."
              />

              <meta
                name="keywords"
                content="recipes, recipe log, recipe app, share recipes, recipe, cook, cookbook"
              />
              <meta
                property="og:description"
                content="Keep all your favorite recipes in one place and easily access them
                from anywhere, anytime. 
                
                Share recipes with your friends and say
                goodbye to messy recipe books and hello to a seamless cooking
                experience."
              />
              <meta name="og:url" content="" />

              <meta
                key="image"
                property="og:image"
                content={"https://www.recipelog.app/CookingLogo.png"}
              />
              <meta property="og:type" content="website" />
              //TODO: Add the viewport back in.
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
              />
            </Head> */
}
