import {
  ApplicationInsights,
  IApplicationInsights,
} from "@microsoft/applicationinsights-web";

let appInsights = null;

if (typeof window !== "undefined") {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: "076ffd2d-42e0-405d-a87b-b20897e421ea",
      /* ...Other Configuration Options... */
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export default appInsights as IApplicationInsights;
