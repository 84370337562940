const sessionStorageKeys = {
  recipeIdMarker: "scroll-position-recipe-id-marker",
  recipeCollectionIdMarker: "scroll-position-recipeCollection-id-marker",
  recipesSortBy: "recipes-sort-by",
  recipesMealPlannerSortBy: "recipes-mealplanner-sort-by",
  recipeCollectionsSortBy: "recipe-collections-sort-by",
  recipeCollectionRecipesSortBy: "recipe-collections-recipes-sort-by",
};

export default sessionStorageKeys;
