export default {
  recipes: "recipes",
  recipesAdmin: "recipesAdmin",
  recipeCollections: "recipeCollections",
  users: "users",
  shoppingLists: "shoppingLists",
  appVersion: "appVersion",
  checkUserHasRecipeAdded: "checkUserHasRecipeAdded",
  importFromInstagram: "importFromInstagram",
  mealPlans: "mealPlans",
};
