import { App } from "@capacitor/app";
import { logger } from "@sentry/utils";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import apiClient from "../apiClient/apiClient";
import { isNative } from "../utils";
import queryKeys from "./queryKeys";

const useGetAppVersion = () => {
  const [isUpgradeRequired, setIsUpgradeRequired] = useState(false);

  const {
    data,
    isError,
    isFetching,
    isInitialLoading: isLoading,
  } = useQuery(
    [queryKeys.appVersion],
    async () => {
      return apiClient.getAppVersion(undefined);
    },
    {
      onSuccess: async (data) => {
        const currentAppVersion = (await App.getInfo()).version;

        try {
          if (data.appVersion) {
            if (parseFloat(currentAppVersion) < parseFloat(data.appVersion)) {
              setIsUpgradeRequired(true);
            } else {
              setIsUpgradeRequired(false);
            }
          }
        } catch (error) {
          setIsUpgradeRequired(false);
          logger.error(error);
        }
      },
      //If there's an upgrade required, refetch every 5 seconds to see if the user has upgraded. If not, check every 5 minutes.
      refetchInterval: isUpgradeRequired ? 20000 : 300000,
      onError: (error: AxiosError) => {},
      enabled: isNative,
      staleTime: 0,
    }
  );

  return { isLoading, isError, data, isFetching, isUpgradeRequired };
};

export default useGetAppVersion;
