"use client";

import additionalSortOptions from "./additionalSortOptions";
import sortOptions from "./sortByOptions";

const getSortByOptionFromSessionStorage = (storageKey: string) => {
  if (typeof window !== "undefined") {
    const val = localStorage.getItem(storageKey);
    if (val) {
      return [...sortOptions, ...additionalSortOptions].find(
        (o) => o.value == val
      );
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export default getSortByOptionFromSessionStorage;
